import { makeAutoObservable, action, toJS } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import userServices, {
  IGetNotificationsListResponse,
  ICreateAuthHistoryParams,
  IReplyMemberInvitationParams,
  IGetInvitationRecordsParams,
  IGetUserBindEnterpriseInfoResponse,
} from '@/services/userServices';
import {
  CheckExistType,
  SignInType,
  SignUpType,
  SignOffType,
  SendEmailType,
  ResetPasswordType,
  UserLevelType,
  UserType,
  ResetEmailType,
  ResetPhoneType,
  BindBindGoogleOauthType,
  GetVerifyCodeType,
  UserCourierType,
  GlobalPassType,
  CreatorPassType,
  CancelPassType,
  AuthHistoryType,
  OverseaPlatformParams,
  OperatePlatformParams,
  PlatformCodeParams,
  OperatePlatformType,
  PlatformDataType,
  PlatformIncreaseParams,
  NotificationsStatus,
  NotificationType,
  InvitationRecordType,
  MemberInviteStatusType,
} from './types';
// import ChannelService from '@/utils/ChannelService';
// import StringUtils from '@/utils/StringUtils';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

export default class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'UserStore',
      properties: ['isOpenPlatform', 'userToken', 'isTrialVersion', 'appliedPlatformIds'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async checkExist(params: CheckExistType) {
    const res = await userServices.checkExist(params);
    return Promise.resolve(res);
  }

  async signIn(params: SignInType) {
    const res = await userServices.signIn(params);
    return Promise.resolve(res);
  }

  async googleAuthState() {
    const res = await userServices.googleAuthState();
    return Promise.resolve(res.state);
  }

  async sendEmailLink(params: SendEmailType) {
    const res = await userServices.sendEmailLink(params);
    return Promise.resolve(res);
  }

  async signUp(params: SignUpType) {
    const res = await userServices.signUp(params);
    return Promise.resolve(res);
  }

  async resetPassword(params: ResetPasswordType) {
    const res = await userServices.resetPassword(params);
    return Promise.resolve(res);
  }

  async signOff(params: SignOffType) {
    const res = await userServices.signOff(params);
    return Promise.resolve(res);
  }

  // 获取user info
  async getUser() {
    const res = await userServices.getUser();
    this.saveUser(res);
    return Promise.resolve(res);
  }

  // 获取体验版token
  async getTrialToken() {
    const res = await userServices.getTrialToken();
    this.changeTrialVersion(res.accessToken);
    return Promise.resolve(res);
  }

  userToken = ''; // 用户token
  isTrialVersion = false; // 是否是体验版本
  // 切换为体验版本 (记录用户token, token切换为体验token)
  changeTrialVersion(accessToken: string) {
    const token = Cookies.get('token');
    this.userToken = token || '';
    Cookies.set('token', accessToken, { expires: 15 });
    this.isTrialVersion = true;
  }

  // 退出体验版本
  exitTrialVersion() {
    Cookies.remove('token');
    Cookies.set('token', this.userToken, { expires: 15 });
    this.isTrialVersion = false;
  }

  // 获取验证码
  async getVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.getVerifyCode(params);
    return Promise.resolve(res);
  }

  // 验证手机号/邮箱
  async checkVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.checkVerifyCode(params);
    return Promise.resolve(res);
  }

  // 重置邮箱
  async resetEmail(params: ResetEmailType) {
    const res = await userServices.resetEmail(params);
    return Promise.resolve(res);
  }

  // 重置手机号
  async resetPhone(params: ResetPhoneType) {
    const res = await userServices.resetPhone(params);
    return Promise.resolve(res);
  }

  // 绑定谷歌账号
  async bindGoogle(params: BindBindGoogleOauthType) {
    const res = await userServices.bindGoogle(params);
    return Promise.resolve(res);
  }

  // 获取登录信息
  async getSignInInfo() {
    const res = await userServices.getSignInInfo();
    return Promise.resolve(res);
  }

  // 移除登录信息
  async removeSignInInfo(id: number) {
    const res = await userServices.removeSignInInfo(id);
    return Promise.resolve(res);
  }

  // 获取Courier设置 (邮件语言及通知状态)
  async getCourier() {
    const res = await userServices.getCourier();
    return Promise.resolve(res);
  }

  // 更新Courier设置 (邮件语言及通知状态)
  async updateCourier(params: UserCourierType) {
    const res = await userServices.updateCourier(params);
    return Promise.resolve(res);
  }

  avatar: string = '';
  countryCode: string = '';
  email: string = '';
  gender: number = 0;
  gid: string = '';
  level: UserLevelType = 'Free';
  nickname: string = '';
  password: string = '';
  phone: string = '';
  role: string = '';
  uid: number = 0;
  saveUser(user: UserType) {
    this.avatar = user.avatar || '';
    this.countryCode = user.countryCode || '';
    this.email = user.email || '';
    this.gender = user.gender || 0;
    this.gid = user.gid || '';
    this.level = user.level;
    this.nickname = user.nickname || '';
    this.password = user.password || '';
    this.phone = user.phone || '';
    this.role = user.role || '';
    this.uid = user.uid || 0;
  }

  clearUser() {
    this.avatar = '';
    this.countryCode = '';
    this.email = '';
    this.gender = 0;
    this.gid = '';
    this.level = 'Free';
    this.nickname = '';
    this.password = '';
    this.phone = '';
    this.role = '';
    this.uid = 0;
  }

  // 获取Global Pass信息
  globalPass: GlobalPassType = {
    id: 0,
    copyUrl: '',
    copyMoreUrl: '',
    status: 'CREATE',
  };
  isPassGlobal: boolean = false;
  isGlobalApply: boolean = false; // 用户是否已经申请Global Pass认证
  async getGlobalPass(params?: boolean) {
    const res = await userServices.getGlobalPass(params);
    this.saveGlobalPass(res);
    return Promise.resolve(res);
  }

  saveGlobalPass(pass: GlobalPassType) {
    if (pass.id) {
      this.globalPass = pass;
      this.isPassGlobal = !!pass.id;
      this.isGlobalApply = pass.status !== 'CREATE';
    }
  }

  // 提交Global Pass
  async submitGlobalPass(params: GlobalPassType) {
    const res = await userServices.submitGlobalPass(params);
    return Promise.resolve(res);
  }

  // 获取Creator Pass信息
  creatorPass: CreatorPassType = {
    id: 0,
    homepageCopyUrl: '',
    homepageMoreCopyUrl: '',
    platformId: 99,
    status: 'CREATE',
  };
  isPassCreator: boolean = false;
  async getCreatorPass() {
    const res = await userServices.getCreatorPass();
    this.saveCreatorPass(res.foreignPlatform);
    return Promise.resolve(res);
  }

  saveCreatorPass(pass: CreatorPassType) {
    if (pass.id) {
      this.creatorPass = pass;
      this.isPassCreator = pass.status === 'SUCCEED';
    }
  }

  // 提交Creator Pass
  async submitCreatorPass(params: CreatorPassType) {
    const res = await userServices.submitCreatorPass(params);
    return Promise.resolve(res);
  }

  async cancelAuthPass(params: CancelPassType) {
    const res = await userServices.cancelAuthPass(params);
    return Promise.resolve(res);
  }

  // 获取认证历史
  async getAuthHistory() {
    const res = await userServices.getAuthHistory();
    this.saveHistoryList(res.authHistories, res.count);
    return Promise.resolve(res);
  }

  //创建认证历史
  async createAuthHistory(params: ICreateAuthHistoryParams) {
    const res = await userServices.createAuthHistory(params);
    return Promise.resolve(res);
  }

  authHistory: AuthHistoryType[] = [];
  authHistoryCount: number = 0;
  saveHistoryList(list: AuthHistoryType[], count: number) {
    this.authHistory = list;
    this.authHistoryCount = count;
  }

  clearPass() {
    this.globalPass = {
      id: 0,
      copyUrl: '',
      copyMoreUrl: '',
      status: 'CREATE',
    };
    this.creatorPass = {
      id: 0,
      homepageCopyUrl: '',
      homepageMoreCopyUrl: '',
      platformId: 99,
      status: 'CREATE',
    };
    this.isPassGlobal = false;
    this.isGlobalApply = false;
    this.isPassCreator = false;
    this.authHistory = [];
    this.authHistoryCount = 0;
  }

  // 创建海外平台
  async createOverseaPlatform(params: OverseaPlatformParams) {
    const res = await userServices.createOverseaPlatform(params);
    return Promise.resolve(res);
  }

  // 查询我运营中的平台列表
  async getOperatePlatform() {
    const res = await userServices.getOperatePlatform();
    this.saveOperatePlatform(res.list);
    return Promise.resolve(res);
  }

  // 是否有已经开通完成的平台
  isOpenPlatform: boolean = false;
  // 是否展示升级Plan后的解冻平台弹窗: (升级需要解冻条件: 未冻结平台大于1个,小于plan对应的个数 且 有冻结平台,即总平台数大于plan对应的个数)
  needUpgradeUnfreeze: boolean = false;
  // 是否展示降级Plan后的解冻平台弹窗: (降级需要解冻条件: 降级后所有平台都会变成冻结状态)
  needDowngradeUnfreeze: boolean = false;
  // 开通的平台列表 (包含 申请中、申请失败、开通完成)
  operatePlatforms: OperatePlatformType[] = [];
  tempOperatePlatforms: OperatePlatformType[] = []; // 临时存放开通平台数据
  operatePlatformsDoneList: OperatePlatformType[] = []; // 开通已完成的平台
  saveOperatePlatform(list: OperatePlatformType[]) {
    this.tempOperatePlatforms = list;
    this.operatePlatformsDoneList = list.filter((plat) => plat.openStatus !== 'OS_DOING');

    let opened = false;
    let unfreeze = true;
    let unfreezePlatformCount = 0;
    if (list.length > 0) {
      list.forEach((item) => {
        if (item.openStatus !== 'OS_DOING') {
          opened = true;
        }
        if (!item.isFroze) {
          unfreeze = false;
          unfreezePlatformCount += 1;
        }
      });

      this.isOpenPlatform = opened;
      this.needDowngradeUnfreeze = unfreeze;

      const planCount = this.level === 'Free' ? 1 : this.level === 'Basic' ? 3 : 99;
      this.needUpgradeUnfreeze =
        unfreezePlatformCount > 0 && unfreezePlatformCount < planCount && list.length > planCount;
    }
    // 请求平台数据
    this.getPlatformData().catch(() => {});
  }

  platformData: PlatformDataType[] = [];
  platformTotal: PlatformDataType = {};
  platformTotalIncrease: PlatformDataType = {};
  // 获取平台数据
  async getPlatformData() {
    const res = await userServices.getPlatformData();
    this.savePlatformData(res.byPlatform, res.incr, res.sum);
    return Promise.resolve(res);
  }

  // 保存平台数据
  savePlatformData(list: PlatformDataType[], increase: PlatformDataType, total: PlatformDataType) {
    this.platformData = list;
    this.platformTotalIncrease = increase;
    this.platformTotal = total;
    this.handlePlatformInfo();
  }

  async getPlatformIncreaseData(params: PlatformIncreaseParams) {
    const res = await userServices.getPlatformIncreaseData(params);
    return Promise.resolve(res);
  }

  // 合并平台信息和平台数据
  handlePlatformInfo() {
    const mergedArray = this.tempOperatePlatforms.map((platA) => {
      const matchingItem = this.platformData.find((platB) => platB.platformId === platA.platformId);

      if (matchingItem) {
        return {
          ...platA,
          sum: matchingItem,
        };
      }
      return platA;
    });

    this.sortOperatePlatforms(mergedArray);
  }

  sortOperatePlatforms(list: OperatePlatformType[], type = 'default') {
    const statusOrder = {
      OS_DONE_BOUND: 0,
      OS_DONE_BINDING: 1,
      OS_DONE_UNBIND: 2,
      OS_DOING: 3,
      OS_UNKNOWN: 10,
    };

    // 排序
    const sorted = list.sort((a, b) => {
      if (a.isFroze !== b.isFroze) {
        return Number(a.isFroze) - Number(b.isFroze);
      }
      if (a.openStatus !== b.openStatus) {
        return statusOrder[a.openStatus] - statusOrder[b.openStatus];
      }
      // 当 status 为 'DONE' 时，按照 play / like 从大到小排序
      if (a.openStatus !== 'OS_DOING') {
        if (type === 'likes') {
          return (b.sum?.likes || 0) - (a.sum?.likes || 0);
        } else if (type === 'plays') {
          return (b.sum?.plays || 0) - (a.sum?.plays || 0);
        } else {
          return dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
            ? -1
            : dayjs(a.createdAt).isSame(dayjs(b.createdAt))
            ? 0
            : 1;
        }
      }

      return 0;
    });

    this.operatePlatforms = sorted;
    this.tempOperatePlatforms = sorted;
    this.operatePlatformsDoneList = sorted.filter((item) => item?.openStatus !== 'OS_DOING');
  }

  clearPlatform() {
    this.isOpenPlatform = false;
    this.operatePlatforms = [];
    this.tempOperatePlatforms = [];
    this.platformData = [];
    this.platformTotal = {};
    this.platformTotalIncrease = {};
    this.operatePlatformsDoneList = [];
  }

  // 申请中的平台id,用来判断开通完成后 获得point弹窗, 现在只有第一次开通平台才能获得point
  appliedPlatformIds: { [uid: number]: number[] } = {};
  // 创建运营平台
  async createOperatePlatform(params: OperatePlatformParams) {
    const res = await userServices.createOperatePlatform(params);
    // 保存申请平台的id,现在只有第一次开通平台才能获得point
    const pidList = this.appliedPlatformIds[this.uid] || [];
    if (this.operatePlatforms.length === 0 && !pidList.includes(params.pid)) {
      pidList.push(params.pid);
      this.appliedPlatformIds[this.uid] = pidList;
    }
    return Promise.resolve(res);
  }

  //保存channel id
  channelId: string = '';
  platformUid: number = 99;
  saveChannelUrl(channelId: string, platformUid: number) {
    this.channelId = channelId;
    this.platformUid = platformUid;
  }

  openingPlatformId: number = 0;
  saveOpeningPlatformId(platformId: number) {
    this.openingPlatformId = platformId;
  }

  //保存global pass
  normalPhotoUrl: string = '';
  holdPhotoUrl: string = '';
  saveGlobalPassPhoto(normalPhotoUrl: string, holdPhotoUrl: string) {
    this.normalPhotoUrl = normalPhotoUrl;
    this.holdPhotoUrl = holdPhotoUrl;
  }

  //清除开通平台数据
  clearData() {
    this.platformUid = 99;
    this.channelId = '';
    this.normalPhotoUrl = '';
    this.holdPhotoUrl = '';
  }

  // 进入到提交验证码页面,准备输入验证码
  async readyToGetCode(params: PlatformCodeParams) {
    const res = await userServices.readyToGetCode(params);
    return Promise.resolve(res);
  }

  // 发送/提交验证码 (code为-1时为重新发送, 传code时为提交)
  async submitPlatformCode(params: PlatformCodeParams) {
    const res = await userServices.submitPlatformCode(params);
    return Promise.resolve(res);
  }

  // 获取用户输入的验证码,用来判断用户有没有输入过
  async getUserPlatformCode(pid: number, sessionType: string) {
    const res = await userServices.getUserPlatformCode(pid, sessionType);
    return Promise.resolve(res);
  }

  // 是否展示开通平台获得point的弹窗
  displayPlatformGetPoint(): boolean {
    let display = false;

    this.operatePlatformsDoneList.forEach((plat) => {
      // 当申请中的平台开通完成后,显示获得硬币,并从appliedPlatformIds移除
      const pidList = this.appliedPlatformIds[this.uid] || [];
      if (pidList.includes(plat.platformId)) {
        display = true;
        this.appliedPlatformIds[this.uid] = pidList.filter((item) => item !== plat.platformId);
      }
    });

    return display;
  }

  unreadCount: number = 0; // 未读消息数
  unreadMessageList: IGetNotificationsListResponse = { count: 0, list: [] }; // 未读消息列表
  undeletedMessageList: IGetNotificationsListResponse = { count: 0, list: [] }; // 全部消息列表
  unreadEphemeralIds: number[] = []; // 临时未读消息id列表

  saveUnreadList(list: NotificationType[]) {
    this.unreadMessageList.list = list;
  }

  saveUnreadCount(value: number) {
    this.unreadCount = value;
  }

  saveUnreadCountIds(ids: number[]) {
    this.unreadEphemeralIds.push(...ids);
  }

  saveMessageList(res: IGetNotificationsListResponse, status: NotificationsStatus) {
    if (status === 'UNREAD') {
      // 未读消息列表初始添加status: "UNREAD"标记
      const list = toJS(res.list)?.map((item) => {
        let status = { status: 'UNREAD' };
        Object.assign(item, status);
        return item;
      });
      this.unreadCount = res.count;
      this.unreadMessageList.count = res.count;
      this.unreadMessageList.list = list;
    } else {
      this.undeletedMessageList.count = res.count;
      this.undeletedMessageList.list = res.list;
    }
  }

  // 收到新的开通平台消息
  isNewPlatformMessage = false;
  setIsNewPlatformMessage(value: boolean) {
    this.isNewPlatformMessage = value;
  }

  clearNotifications() {
    this.unreadCount = 0;
    this.unreadMessageList.count = 0;
    this.unreadMessageList.list = [];
    this.undeletedMessageList.count = 0;
    this.undeletedMessageList.list = [];
    this.isNewPlatformMessage = false;
  }

  // 获取通知列表
  async getNotificationsList(status: NotificationsStatus, limit: number = 100, offset: number = 0) {
    const params = {
      limit,
      offset,
      status,
    };
    const res = await userServices.getNotificationsList(params);
    this.saveMessageList(res, status);
    this.unreadEphemeralIds = [];
    return Promise.resolve(res);
  }

  // 更新消息状态(已读，删除等)
  async updateNotifications(ids: number[], status: NotificationsStatus) {
    const params = {
      ids,
      status,
    };
    const res = await userServices.updateNotifications(params);
    return Promise.resolve(res);
  }

  // 获取邀请码
  async getInvitationCode() {
    const res = await userServices.getInvitationCode();
    return Promise.resolve(res);
  }

  // 获取邀请历史
  async getInvitationHistory() {
    const res = await userServices.getInvitationHistory();
    return Promise.resolve(res);
  }

  // 冻结运营平台
  async freezeOperatingPlatform(ids: number[]) {
    const res = await userServices.freezeOperatingPlatform({ ids });
    return Promise.resolve(res);
  }

  // 回复成员邀请
  async replyMemberInvitation(params: IReplyMemberInvitationParams) {
    const res = await userServices.replyMemberInvitation(params);
    return Promise.resolve(res);
  }

  // 获取邀请记录
  async getInvitationRecords(inStatus?: MemberInviteStatusType[]) {
    const params: IGetInvitationRecordsParams = {
      limit: 999,
      offset: 0,
      inStatus,
    };
    const res = await userServices.getInvitationRecords(params);
    this.filterInvitationRecords(res.list || []);
    return Promise.resolve(res);
  }

  invitationRecords: InvitationRecordType[] = [];
  filterInvitationRecords(list: InvitationRecordType[]) {
    const latestData: { [eid: number]: InvitationRecordType } = {};

    // 遍历数组
    list.forEach((item) => {
      const currentCreatedTime = dayjs(item?.invitation?.createdTime);
      const latestCreatedTime = latestData[item?.invitation?.eid]
        ? dayjs(latestData[item?.invitation?.eid]?.invitation?.createdTime)
        : null;

      // 如果该 eid 在 latestData 中不存在，或者该项的创建时间比 latestData 中存储的时间新，则更新 latestData 中的数据
      if (!latestCreatedTime || currentCreatedTime.isAfter(latestCreatedTime)) {
        latestData[item?.invitation?.eid] = item;
      }
    });

    Object.values(latestData).forEach((item) => {
      if (item.records?.[0]?.status !== 'INVITATION_STATUS_SENT') {
        delete latestData[item.invitation.eid];
      }
    });

    this.invitationRecords = Object.values(latestData).sort((a, b) => {
      return (
        dayjs(b?.records?.[0]?.createdTime).valueOf() -
        dayjs(a?.records?.[0]?.createdTime).valueOf()
      );
    });
  }

  enterpriseInfo: IGetUserBindEnterpriseInfoResponse = {
    member: {
      id: 0,
      eid: 0,
      uid: 0,
      acceptedTime: '',
      createdTime: '',
    },
    invitation: {
      id: 0,
      uid: 0,
      userName: '',
      eid: 0,
      enterpriseName: '',
      acceptedTime: '',
      expiredTime: '',
      createdTime: '',
    },
  };
  saveEnterpriseInfo(info: IGetUserBindEnterpriseInfoResponse) {
    this.enterpriseInfo = info;
  }

  // 查询用户当前绑定的企业信息
  async getUserBindEnterpriseInfo() {
    const res = await userServices.getUserBindEnterpriseInfo();
    this.saveEnterpriseInfo(res);
    return Promise.resolve(res);
  }

  clearEnterprise() {
    this.invitationRecords = [];
    this.enterpriseInfo = {
      member: {
        id: 0,
        eid: 0,
        uid: 0,
        acceptedTime: '',
        createdTime: '',
      },
      invitation: {
        id: 0,
        uid: 0,
        userName: '',
        eid: 0,
        enterpriseName: '',
        acceptedTime: '',
        expiredTime: '',
        createdTime: '',
      },
    };
  }

  clearStore() {
    this.clearUser();
    this.clearPass();
    this.clearPlatform();
    this.clearNotifications();
    this.clearEnterprise();
  }
}
